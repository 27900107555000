body.active-modal {
  overflow-y: hidden;
}

.modal,
.overlay {
  width: 100vw;
  height: 200vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
.modal {
  z-index: 99 !important;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
  z-index: 99 !important;
}
.modal-content {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 35px 10px 10px;
  border-radius: 10px;
  z-index: 999 !important;
}
.modal-content-text {
  padding: 20px;
  font-family: 'Arial';
  font-size: 14px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  width: 30px;
  margin: 10px;
}

@media (max-width: 700px) {
  .modal-content {
    width: 80vw;
  }
  .modal-content-text {
    font-size: 16px;
    padding: 20px 8px;
  }
}

@font-face {
  font-family: 'BebasNeueRegular';
  src: url('../public/fonts/BEYLAF-BebasNeueRegular.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: 'BebasNeuelite';
  src: url('../public/fonts/Bebas-Neue-lite.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: 'BebasNeueBook';
  src: url('../public/fonts/Bebas-Neue-book.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: 'Corvetta';
  src: url('../public/fonts/Corvetta.ttf') format('truetype');
  font-display: block;
}

* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}
#root {
  width: 100vw;
}
.none {
  display: none !important;
}
body {
  overflow-x: hidden;
}
:root {
  --pink: rgb(208, 9, 128);
  --darkPink: rgb(213, 8, 127);
  --litePink: rgb(233, 125, 177);
  --white: #fff;
  --darkBlue: rgb(55, 131, 161);
  --liteBlue: rgb(128, 188, 206);
  --index: calc(1vw + 1vh);
}
.center {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.padding-text p {
  padding: 50px;
  font-size: 24px;
}
.title-pink {
  color: var(--pink);
  font-size: 20px;
}
.header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
}
.header__line {
  height: calc(var(--index) * 0.6);
  background: linear-gradient(to right, rgb(0, 151, 159), rgb(128, 188, 206));
}
.header__box {
  display: flex;
  height: calc(var(--index) * 3.5);
  background-color: var(--pink);
}
.header__logo1 img {
  width: calc(var(--index) * 3.5);
  height: calc(var(--index) * 2.2);
  padding-top: calc(var(--index) * 0.6);
  padding-left: calc(var(--index) * 0.5);
}

.social__whatsapp:hover,
.social__telegram:hover,
.social__instagram:hover,
.social__vk:hover,
.header__nav li:hover {
  scale: 1.2;
  transition: scale 0.2s ease-in;
  cursor: pointer;
}
.header__social-box {
  display: flex;
  margin: auto;
}
.header__social {
  display: flex;
  justify-content: center;
  padding-right: 10px;
}
.header__social img {
  width: calc(var(--index) * 1.2);
  margin-left: calc(var(--index) * 0.5);
}
.header__tel a {
  text-decoration: none;
  color: var(--white);
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 1);
}
.header__nav {
  margin: auto;
}
.header__nav ul {
  display: flex;
  list-style: none;
  text-transform: uppercase;
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 0.9);
  color: var(--white);
}
.header__nav li {
  padding-right: calc(var(--index) * 2);
}
.header__nav li:nth-child(3) {
  padding-right: calc(var(--index) * 1.3);
}
.header__nav li a {
  text-decoration: none;
  color: var(--white);
}
.registraition__button {
  border: 1px solid var(--white);
  padding: calc(var(--index) * 0.5) calc(var(--index) * 1);
  border-radius: 8px;
  margin-top: calc(var(--index) * -0.5);
  text-decoration: none;
  color: var(--white);
  transition: 0.5s ease-in;
}
.registraition__button:hover {
  color: var(--pink);
  background-color: var(--white);
  box-shadow: 0px 0px 20px #ffffff;
}
/* home */
.baner__button {
  text-decoration: none;
  color: var(--white);
  background-color: var(--pink);
  padding: calc(var(--index) * 0.5) calc(var(--index) * 1);
  border-radius: calc(var(--index) * 2);
  box-shadow: 1px 1px 25px var(--white);
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 1.2);
  transition: 0.5s ease-in;
  display: flex;
  width: max-content;
  margin-bottom: 15px;
  margin-top: 24px;
}
.baner__button:hover {
  color: var(--pink);
  background-color: var(--white);
  font-family: 'BebasNeueRegular';
}
.homeBaner__title {
  font-size: 20px;
  display: flex;
  justify-content: center;
  font-family: 'BebasNeueRegular';
  color: var(--pink);
  letter-spacing: 3px;
  margin-top: 24px;
}
.home {
  position: relative;
  padding-top: calc(var(--index) * 5.1);
  display: flex;
}
.home__img {
  width: calc(var(--index) * 30);
  display: flex;
  border-radius: 0 calc(var(--index) * 10) calc(var(--index) * 10) 0;
  margin-left: calc(var(--index) * -3);
  margin-bottom: calc(var(--index) * 3);
}
.home__content {
  position: absolute;
  right: 0;
  top: calc(var(--index) * 5);
  margin-left: auto;
}
.newYear__month-img {
  width: calc(var(--index) * 10);
}
.home__box {
  display: flex;
  /* background-color: blue; */
  z-index: 4;
}
.home__button-box {
  position: relative;
  padding-top: calc(var(--index) * 5);
  z-index: -1;
}
.home__button-festival {
  color: var(--white);
  background-color: var(--darkBlue);
  text-transform: uppercase;
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 2);
  letter-spacing: 2px;
  border-radius: calc(var(--index) * 10) 0 0 calc(var(--index) * 10);
  width: max-content;
  padding: calc(var(--index) * 0.5) calc(var(--index) * 4);
  box-shadow: -5px 5px 15px #666;
}
.home__button-ashram {
  position: absolute;
  right: 0;
  bottom: calc(var(--index) * -2);
  color: var(--white);
  background-color: var(--pink);
  border-radius: calc(var(--index) * 10) 0 0 calc(var(--index) * 10);
  width: max-content;
  padding: calc(var(--index) * 0.5) calc(var(--index) * 2);
  font-family: 'BebasNeueBook';
  font-size: calc(var(--index) * 1.2);
  letter-spacing: 1px;
  box-shadow: -5px 5px 15px #666;
  margin-left: auto;
}
.home__date {
  opacity: 0;
  animation: ani 1s forwards;
}
@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.home__date {
  text-align: center;
}
.home__date h3 {
  color: var(--darkPink);
  font-size: calc(var(--index) * 7.3);
  font-family: 'BebasNeueBook';
  /* margin-left: calc(var(--index) * 1.6); */
  margin-top: calc(var(--index) * -1.5);
  padding-top: calc(var(--index) * 1);
  z-index: 3;
}
.home__date h2 {
  color: var(--liteBlue);
  font-size: calc(var(--index) * 4);
  font-family: 'BebasNeueBook';
  z-index: 3;
  letter-spacing: 2px;
}
.home__month {
  display: flex;
  position: relative;
  z-index: 3;
}
.home__date-jun {
  margin-left: calc(var(--index) * -1);
  color: var(--white);
  background-color: var(--litePink);
  border-radius: calc(var(--index) * 0.8);
  font-size: calc(var(--index) * 1.5);
  font-weight: 500;
  padding: calc(var(--index) * 0.01) calc(var(--index) * 0.9) calc(var(--index) * 0.15)
    calc(var(--index) * 2.2);
  border: none;
}
.home__button-present {
  color: var(--white);
  background-color: var(--darkBlue);
  border-radius: 0 calc(var(--index) * 2) calc(var(--index) * 2) 0;
  padding: calc(var(--index) * 0.5) calc(var(--index) * 0.8);
  padding-left: calc(var(--index) * 2);
  width: max-content;
  box-shadow: 5px 5px 15px #666;
  z-index: 4;
  display: flex;
  font-family: 'BebasNeueBook';
  position: absolute;
  animation: move 1s;
  transition: move 0.5s ease-in;
}
@keyframes move {
  0% {
    left: calc(var(--index) * -8);
  }
  100% {
    left: 0;
  }
}
.home__button-present0 {
  bottom: calc(var(--index) * 3.5);
}
.home__button-present2 {
  bottom: calc(var(--index) * 1.4);
}
.home__button-present3 {
  bottom: calc(var(--index) * -1.7);
}
.home__button-present-pink {
  color: var(--white);
  padding: 0 10px;
  background-color: var(--pink);
  padding-left: calc(var(--index) * 2);
}
.home__sochi {
  color: var(--liteBlue);
  opacity: 0.5;
  text-transform: uppercase;
  font-family: 'BebasNeueBook';
  font-size: calc(var(--index) * 4);
  letter-spacing: 3px;
  text-align: right;
  padding-right: calc(var(--index) * 1.5);
}
.home__sochi-mobile {
  display: none;
}
.home__img-treeth {
  width: 100vw;
  position: absolute;
  bottom: calc(var(--index) * 0);
}
.home__img-snowflake {
  position: absolute;
  top: calc(var(--index) * 5);
  left: calc(var(--index) * 35);
  opacity: 0.2;
  z-index: 1;
  width: calc(var(--index) * 17);
}

/* vedalife */
.vedalife {
  padding-top: calc(var(--index) * 3);
  display: flex;
  column-gap: calc(var(--index) * 3);
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
}
.vedalife__img img {
  width: calc(var(--index) * 24);
  border-radius: 50%;
}
.vedalife__content {
  padding-right: calc(var(--index) * 3);
  padding-left: calc(var(--index) * 4);
  max-width: calc(var(--index) * 28);
}
.vedalife__logoPuri img {
  width: calc(var(--index) * 18);
}
.vedalife__content p {
  padding-bottom: calc(var(--index) * 1);
}
.vedalife__title {
  color: var(--pink);
  text-transform: uppercase;
  font-family: 'BebasNeueRegular';
  font-size: 27px;
  letter-spacing: 3px;
  padding-top: calc(var(--index) * 2.3);
  padding-bottom: calc(var(--index) * 1);
}
.vedalife__text {
  font-size: 18px;
  line-height: 1.5;
}
/* video */
.video {
  margin: auto;
  margin-top: calc(var(--index) * 2.5);
  width: 50vw;
  height: 25vw;
}
.active {
  animation: active 1s ease-in;
}
@keyframes active {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* new year */
.newYear {
  background-color: var(--litePink);
  display: flex;
  align-items: center;
  margin-top: calc(var(--index) * 2.8);
  justify-content: right;
  position: relative;
}
.newYear__date {
  margin: auto;
  padding-right: calc(var(--index) * 1.5);
}
.newYear__date h3 {
  color: var(--white);
  font-size: calc(var(--index) * 7.3);
  font-family: 'BebasNeueBook';
  margin-left: calc(var(--index) * 1.6);
  margin-top: calc(var(--index) * -1.5);
  padding-top: calc(var(--index) * 1);
  z-index: 4;
  position: relative;
}
.newYear__month {
  display: flex;
  position: relative;
  padding-bottom: calc(var(--index) * 1);
  z-index: 3;
}
.newYear__date-dec {
  color: var(--white);
  background-color: var(--darkPink);
  border-radius: calc(var(--index) * 0.8);
  font-size: calc(var(--index) * 1.5);
  font-weight: 500;
  padding: calc(var(--index) * 0.01) calc(var(--index) * 0.9) calc(var(--index) * 0.15)
    calc(var(--index) * 1.4);
  border: none;
  z-index: 9;
}
.newYear__date-jun {
  position: absolute;
  left: calc(var(--index) * 6.5);
  color: var(--pink);
  background-color: var(--white);
  border-radius: calc(var(--index) * 0.8);
  font-size: calc(var(--index) * 1.5);
  font-weight: 500;
  padding: calc(var(--index) * 0.01) calc(var(--index) * 0.9) calc(var(--index) * 0.15)
    calc(var(--index) * 2.2);
  border: none;
}
.newYear__snowflake img {
  width: calc(var(--index) * 8);
  height: calc(var(--index) * 8);
  position: absolute;
  opacity: 0.5;
  top: calc(var(--index) * 1);
  left: calc(var(--index) * 1);
  z-index: 1;
}
.newYear__title h3 {
  font-size: calc(var(--index) * 3);
  font-family: 'BebasNeueBook';
  text-align: center;
  margin: 0;
  color: var(--darkPink);
  margin-right: 100px;
}

/* festival */
.festival {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: calc(var(--index) * 6);
  padding-bottom: calc(var(--index) * 6);
}
.festival__content {
  width: calc(var(--index) * 25);
  align-items: center;
  margin: auto 0;
  margin-right: calc(var(--index) * 3);
  margin-left: 15px;
}
.festival__title {
  font-size: calc(var(--index) * 1);
  font-family: 'BebasNeueBook';
  padding-bottom: calc(var(--index) * 1);
  color: var(--pink);
}
.festival__text {
  font-size: 18px;
}
.festival__text p {
  padding-bottom: calc(var(--index) * 1);
  line-height: 1.5;
}
.festival__img img {
  border-radius: 50%;
  width: calc(var(--index) * 23);
}
.skeleton23 {
  background-color: var(--white);
  border-radius: 50%;
  width: calc(var(--index) * 23);
  height: calc(var(--index) * 23);
}
.festival__snowflake1 {
  position: absolute;
  bottom: calc(var(--index) * 1);
  left: calc(var(--index) * -1.5);
  width: calc(var(--index) * 10);
  z-index: -1;
  opacity: 0.3;
}
.festival__snowflake2 {
  position: absolute;
  z-index: -1;
  opacity: 0.3;
  width: calc(var(--index) * 10);
  top: calc(var(--index) * 1);
}
.festival__btn {
  position: absolute;
  bottom: calc(var(--index) * 7.7);
  right: calc(var(--index) * -5);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkPink);
  border-radius: 28px;
}
/* mandarin */
.mandarin__img {
  width: 100%;
}
/* programmFestival */
.programmFestival {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: calc(var(--index) * 4);
  padding-bottom: calc(var(--index) * 6);
}
.programmFestival__content {
  width: calc(var(--index) * 25);
  align-items: center;
  margin: auto 0;
  margin-right: 15px;
}
.programmFestival__title {
  font-size: calc(var(--index) * 1);
  font-family: 'BebasNeueBook';
  padding-bottom: calc(var(--index) * 1);
  color: var(--pink);
  text-transform: uppercase;
}
.programmFestival__text {
  font-size: 18px;
}
.programmFestival__text p,
.programmFestival__text ul {
  padding-bottom: calc(var(--index) * 1);
  line-height: 1.5;
}
.programmFestival__text li {
  list-style-type: none;
}
.programmFestival__img {
  margin: auto 0;
}
.programmFestival__img img {
  border-radius: 50%;
  width: calc(var(--index) * 23);
  margin-right: calc(var(--index) * 3);
}
.programmFestival__snowflake1 {
  position: absolute;
  top: calc(var(--index) * 10);
  right: calc(var(--index) * -1);
  width: calc(var(--index) * 11);
  z-index: -1;
  opacity: 0.2;
}
.programmFestival__btn {
  position: absolute;
  bottom: calc(var(--index) * 6);
  left: calc(var(--index) * -1.5);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkBlue);
  border-radius: 28px;
}
/* table */
.table {
  padding: calc(var(--index) * 1);
  background-color: var(--darkBlue);
  display: flex;
}
.table__img {
  display: flex;
  margin: 0 auto;
  width: 95%;
  box-shadow: calc(var(--index) * 0.5) calc(var(--index) * 0.5) calc(var(--index) * 1) #666;
}
.table a {
  text-decoration: none;
  color: var(--pink);
  background-color: var(--white);
  padding: calc(var(--index) * 0.5) calc(var(--index) * 1);
  border-radius: calc(var(--index) * 1);
  box-shadow: 1px 1px 25px var(--white);
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 1.2);
  font-weight: 600;
  letter-spacing: 1.1px;
  transition: 0.5s ease-in;
  margin: 0 auto;
}
.table a:hover {
  color: var(--pink);
  background-color: var(--white);
}
.table__title {
  color: var(--white);
  font-family: 'BebasNeueBook';
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
  font-size: calc(var(--index) * 1.5);
  font-weight: 600;
  letter-spacing: 1.5px;
}
/* description */
.description {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: calc(var(--index) * 5);
  /* padding-bottom: calc(var(--index) * 6); */
}
.description-title-h1 {
  text-align: center;
  margin-top: calc(var(--index) * 2);
  font-family: 'BebasNeueBook';
  font-size: calc(var(--index) * 2);
  color: var(--darkBlue);
}
.description__box {
  display: flex;
}
.description__content {
  width: calc(var(--index) * 28);
  align-items: center;
  margin: auto 0;
}
.description__title {
  font-size: calc(var(--index) * 1);
  font-family: 'BebasNeueBook';
  padding-bottom: calc(var(--index) * 1);
  color: var(--pink);
  text-transform: uppercase;
}
.description__text {
  font-size: 18px;
}
.description__text p {
  line-height: 1.5;
}
.description__img img {
  border-radius: 50%;
  width: calc(var(--index) * 10.5);
  margin-right: calc(var(--index) * 3);
}
.skeleton10 {
  background-color: var(--white);
  border-radius: 50%;
  width: calc(var(--index) * 10.5);
  height: calc(var(--index) * 10.5);
}
.description__snowflake1 {
  position: absolute;
  top: calc(var(--index) * 10);
  right: calc(var(--index) * 2.5);
  width: calc(var(--index) * 11);
  z-index: -1;
  opacity: 0.2;
}
.description__btn {
  position: absolute;
  top: calc(var(--index) * 3.6);
  right: calc(var(--index) * -3);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkPink);
  border-radius: 28px;
}
.description__text p {
  padding-bottom: calc(var(--index) * 1);
}
/* box2 */
.box2 {
  display: flex;
  flex-direction: row-reverse;
}
.description__img-revers {
  margin-left: calc(var(--index) * 3);
  /* margin-right: 0; */
}
.description__snowflake2 {
  position: absolute;
  top: calc(var(--index) * 14.5);
  right: calc(var(--index) * 1);
  width: calc(var(--index) * 7);
  z-index: -1;
  opacity: 0.3;
}
.description__snowflake3 {
  position: absolute;
  bottom: calc(var(--index) * 15);
  left: calc(var(--index) * 5);
  width: calc(var(--index) * 11);
  z-index: -1;
  opacity: 0.2;
}
.description__snowflake4 {
  position: absolute;
  bottom: calc(var(--index) * -13);
  right: calc(var(--index) * -7);
  width: calc(var(--index) * 10);
  z-index: -1;
  opacity: 0.3;
}
.description__snowflake5 {
  position: absolute;
  bottom: calc(var(--index) * 1);
  left: calc(var(--index) * -0.5);
  width: calc(var(--index) * 10);
  z-index: -1;
  opacity: 0.3;
}
.description__btnEnd {
  position: absolute;
  bottom: calc(var(--index) * -3.7);
  left: calc(var(--index) * -3);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkBlue);
  border-radius: 28px;
}
.description__btnEnd2 {
  position: absolute;
  bottom: calc(var(--index) * 11);
  left: calc(var(--index) * -4.7);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkBlue);
  border-radius: 28px;
}
.description__btn2 {
  position: absolute;
  top: calc(var(--index) * 21);
  right: calc(var(--index) * -4.5);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkPink);
  border-radius: 28px;
}
/* img кулинарный мастер-класс */
.cooking {
  margin-top: calc(var(--index) * 2.5);
}
.cooking__padding {
  margin: 24px 0;
}
.cooking__img {
  width: 100%;
}
.table {
  margin-top: calc(var(--index) * 4);
  padding: calc(var(--index) * 1);
}
.table__img {
  display: flex;
  margin: 0 auto;
  width: 95%;
  box-shadow: calc(var(--index) * 0.5) calc(var(--index) * 0.5) calc(var(--index) * 1) #666;
}
/* speakers */
.speakers {
  background-color: var(--darkBlue);
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-top: calc(var(--index) * 2);
}
.speakers__date {
  padding-right: calc(var(--index) * 3);
}
.speakers__date h3 {
  color: var(--white);
  font-size: calc(var(--index) * 6.7);
  font-family: 'BebasNeueBook';
  margin-left: calc(var(--index) * 1.6);
  margin-top: calc(var(--index) * -1);
  margin-bottom: 0;
  height: calc(var(--index) * 7);
}
.speakers__month {
  display: flex;
  position: relative;
}
.speakers__date-dec {
  color: var(--white);
  background-color: var(--litePink);
  border-radius: calc(var(--index) * 0.8);
  font-size: calc(var(--index) * 1.5);
  font-weight: 500;
  padding: calc(var(--index) * 0.01) calc(var(--index) * 0.9) calc(var(--index) * 0.15)
    calc(var(--index) * 1.4);
  border: none;
  z-index: 3;
  display: flex;
}
.speakers__date-jun {
  position: absolute;
  right: calc(var(--index) * -2.2);
  color: var(--litePink);
  background-color: var(--white);
  border-radius: calc(var(--index) * 0.8);
  font-size: calc(var(--index) * 1.5);
  font-weight: 500;
  padding: calc(var(--index) * 0.01) calc(var(--index) * 0.9) calc(var(--index) * 0.15)
    calc(var(--index) * 2.2);
  border: none;
  z-index: 1;
  display: flex;
}
.speakers__title h3 {
  font-size: calc(var(--index) * 3.5);
  font-family: 'BebasNeueBook';
  text-align: center;
  margin: 0;
  color: var(--white);
}
.speakers__title p {
  font-size: calc(var(--index) * 1.5);
  font-family: 'BebasNeueBook';
  text-align: center;
  margin: 0;
  color: var(--darkBlue);
  margin: 10px 0;
}
.speakers__snowflake {
  display: none;
}
/* speaker */
.btnModal {
  display: flex;
  margin: auto;
  background-color: var(--liteBlue);
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 0.7);
  letter-spacing: 1.2px;
}
.btnModal p {
  padding-bottom: 20px;
}
.speaker {
  position: relative;
  padding-top: calc(var(--index) * 8.5);
  display: flex;
  justify-content: center;
  z-index: 0;
}
.speaker__box {
  width: calc(var(--index) * 20.5);
  z-index: 0 !important;
}
.speaker__img img {
  border-radius: 50%;
  width: calc(var(--index) * 13);
  margin: auto;
  display: flex;
}
.speaker__title {
  color: var(--darkBlue);
  font-size: calc(var(--index) * 1);
  font-family: 'BebasNeueRegular';
  text-align: center;
  padding-top: calc(var(--index) * 1.2);
  padding-bottom: calc(var(--index) * 0.7);
}
.speaker__text p {
  text-align: center;
  font-size: calc(var(--index) * 0.8);
}
.speaker__snowflake {
  position: absolute;
  top: calc(var(--index) * 1);
  left: calc(var(--index) * 6);
  width: calc(var(--index) * 10);
  z-index: -1;
  opacity: 0.3;
}
.speaker__snowflake1 {
  position: absolute;
  top: calc(var(--index) * 23);
  left: calc(var(--index) * 5);
  width: calc(var(--index) * 7);
  z-index: -1;
  opacity: 0.3;
}
.speaker__snowflake2 {
  position: absolute;
  top: calc(var(--index) * 34);
  right: calc(var(--index) * -4);
  width: calc(var(--index) * 10);
  z-index: -1;
  opacity: 0.3;
}
.speaker__btn1 {
  position: absolute;
  top: calc(var(--index) * 30);
  right: calc(var(--index) * -2.5);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkBlue);
  border-radius: 28px;
}
.speaker__btn2 {
  position: absolute;
  top: calc(var(--index) * 7);
  left: calc(var(--index) * -3);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--pink);
  border-radius: 28px;
}
.speaker2 {
  position: relative;
  padding-top: calc(var(--index) * 3.5);
  display: flex;
  z-index: 0 !important;
}
.speaker2 .speaker__box {
  margin: auto;
}
.speaker__content {
  padding-bottom: calc(var(--index) * 1);
}
.videoAshram {
  display: flex;
  margin: auto;
  margin-top: calc(var(--index) * 2);
  width: 50vw;
  height: 50vh;
  z-index: 0;
}
.Podrobnee {
  font-family: Arial, Helvetica, sans-serif;
}
.podrobnee-li li {
  list-style-type: none;
  margin-bottom: 15px;
}
/* recomendation */
.recomendation {
  background-color: var(--litePink);
  margin-top: calc(var(--index) * 3);
  padding-top: calc(var(--index) * 1);
  padding-bottom: calc(var(--index) * 1);
}
.recomendation__box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 90vw;
  margin: auto;
}
.recomendation__img {
  padding: calc(var(--index) * 0.5);
  margin: auto;
}
.recomendation__img img {
  border-radius: 50%;
  width: 100%;
}
.recomendation__img h3 {
  text-align: center;
  color: var(--white);
  font-family: 'BebasNeueBook';
  letter-spacing: 1.2px;
  font-size: calc(var(--index) * 1);
  padding-top: calc(var(--index) * 1);
}
.recomendation h2 {
  text-align: center;
  color: var(--white);
  font-family: 'BebasNeueBook';
  letter-spacing: 1.2px;
  font-size: calc(var(--index) * 1.5);
  padding-bottom: calc(var(--index) * 1);
}
/* reviews */
.reviews {
  position: relative;
  margin-top: calc(var(--index) * 3);
  padding-top: calc(var(--index) * 1);
  /* padding-bottom: calc(var(--index) * 3.5); */
  color: var(--white);
  text-align: center;
  text-transform: uppercase;
  font-family: 'BebasNeueBook';
  font-size: calc(var(--index) * 2);
  letter-spacing: 1.5px;
}
.reviews h3 {
  background-color: var(--litePink);
  padding-top: calc(var(--index) * 2);
  padding-bottom: calc(var(--index) * 2);
}
.reviews__img-snowflake {
  position: absolute;
  top: calc(var(--index) * 2);
  left: calc(var(--index) * 10);
  width: calc(var(--index) * 4);
  opacity: 0.4;
}
.reviews__img-snowflake-video1 {
  position: absolute;
  top: calc(var(--index) * 15);
  right: calc(var(--index) * 7);
  width: calc(var(--index) * 15);
  z-index: -1;
  opacity: 0.2;
}

/* hram */
.hram {
  position: relative;
  margin-top: calc(var(--index) * 1);
  padding-top: calc(var(--index) * 1);
  padding-bottom: calc(var(--index) * 3.5);
  text-align: center;
}
.hram h3 {
  background-color: var(--liteBlue);
  padding-top: calc(var(--index) * 2);
  padding-bottom: calc(var(--index) * 2);
  text-transform: uppercase;
  font-family: 'BebasNeueBook';
  color: var(--white);
  letter-spacing: 1.5px;
  font-size: calc(var(--index) * 2);
}
.hram__text {
  padding-top: calc(var(--index) * 2);
  width: max-content;
  margin: auto;
}
.hram li {
  text-align: left;
  list-style-type: none;
}
.hram__img-snowflake {
  position: absolute;
  top: calc(var(--index) * 1.3);
  right: calc(var(--index) * 10);
  width: calc(var(--index) * 5);
  opacity: 0.5;
}
.hram__img-snowflake1 {
  position: absolute;
  top: calc(var(--index) * 15);
  left: calc(var(--index) * 5);
  width: calc(var(--index) * 15);
  opacity: 0.2;
  z-index: -1;
}
.hram__img-snowflake2 {
  position: absolute;
  top: calc(var(--index) * 30);
  right: calc(var(--index) * 10);
  width: calc(var(--index) * 10);
  opacity: 0.2;
  z-index: -1;
}
.hram__img-snowflake3 {
  position: absolute;
  top: calc(var(--index) * 75);
  left: calc(var(--index) * 5);
  width: calc(var(--index) * 10);
  opacity: 0.2;
  z-index: -1;
}
.hram__img-snowflake4 {
  position: absolute;
  top: calc(var(--index) * 90);
  right: calc(var(--index) * 10);
  width: calc(var(--index) * 7);
  opacity: 0.2;
  z-index: -1;
}
.hram__img {
  width: 100%;
  padding-top: calc(var(--index) * 2);
  padding-bottom: calc(var(--index) * 2);
}
.videoRoot {
  margin: auto;
  width: 37vw;
  height: 100vh;
  box-shadow: 5px 5px 15px #333;
  z-index: 2;
}
/* slider */
.slider h2 {
  font-family: 'BebasNeueBook';
  color: var(--pink);
  letter-spacing: 1.5px;
  font-size: calc(var(--index) * 2);
  padding-top: calc(var(--index) * 2);
  padding-bottom: calc(var(--index) * 2);
  text-align: center;
}
.slider {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.owl-stage .active {
  animation: none;
}
.owl-nav span {
  font-size: calc(var(--index) * 5);
}
.owl-theme .owl-nav {
  margin-top: calc(var(--index) * -2) !important;
}
.owl-theme .owl-nav:hover {
}
.slider__img-snowflake1 {
  position: absolute;
  top: calc(var(--index) * 5);
  left: calc(var(--index) * -15);
  width: calc(var(--index) * 15);
  opacity: 0.2;
  z-index: -1;
}
.slider__img-snowflake2 {
  position: absolute;
  top: calc(var(--index) * 20);
  right: calc(var(--index) * -10);
  width: calc(var(--index) * 5);
  opacity: 0.2;
  z-index: -1;
}
.slider__img-snowflake3 {
  position: absolute;
  top: calc(var(--index) * 1);
  left: calc(var(--index) * -10);
  width: calc(var(--index) * 8);
  opacity: 0.2;
  z-index: -1;
}
.slider__img-snowflake4 {
  position: absolute;
  top: calc(var(--index) * 20);
  right: calc(var(--index) * -12);
  width: calc(var(--index) * 12);
  opacity: 0.2;
  z-index: -1;
}
.owl-theme {
  color: #000;
  margin-top: calc(var(--index) * 2.5);
}
.item iframe {
  width: 50vw;
  height: 50vh;
}
.owl-nav span {
  font-family: none;
}
/* регистрация */
.registraition {
  position: relative;
  background-color: var(--darkBlue);
  margin-top: calc(var(--index) * 3.5);
  padding-right: calc(var(--index) * 2.5);
  display: flex;
  justify-content: right;
}
.registraition__content {
  margin: auto 0;
  width: calc(var(--index) * 25);
  /* background-color:red; */
  padding-bottom: calc(var(--index) * 4);
  color: var(--white);
  z-index: 3;
  position: relative;
}
.registraition__title {
  font-size: calc(var(--index) * 1.5);
  font-family: 'BebasNeueBook';
  padding-bottom: calc(var(--index) * 1);
  padding-top: calc(var(--index) * 2);
  color: var(--white);
  letter-spacing: 1.5px;
}
.registraition__text {
  font-size: 18px;
}
.registraition__text p {
  margin-bottom: calc(var(--index) * 1);
  line-height: 1.5;
}
.registraition__text p:last-child {
  margin-bottom: calc(var(--index) * 2);
}
.registraition__content a {
  text-decoration: none;
  color: var(--white);
  background-color: var(--pink);
  padding: calc(var(--index) * 0.5) calc(var(--index) * 1);
  border-radius: calc(var(--index) * 2);
  box-shadow: 1px 1px 25px var(--white);
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 1.2);
  transition: 0.5s ease-in;
  display: flex;
  width: max-content;
  margin-bottom: 15px;
}
.registraition__content a:hover {
  color: var(--pink);
  background-color: var(--white);
  font-family: 'BebasNeueRegular';
}
.registraition__text p a:hover {
  cursor: pointer;
  color: var(--darkPink);
}
.registraition__img img {
  border-radius: 50%;
  width: calc(var(--index) * 23);
  margin-top: calc(var(--index) * 1);
  margin-bottom: calc(var(--index) * 1);
  margin-left: calc(var(--index) * 3);
  z-index: 3;
  position: relative;
}
.registraition__snowflake {
  position: absolute;
  top: calc(var(--index) * -8);
  left: calc(var(--index) * 5);
  width: calc(var(--index) * 7);
  opacity: 0.2;
}
.registraition__snowflake2 {
  position: absolute;
  bottom: calc(var(--index) * -1);
  right: calc(var(--index) * 22);
  width: calc(var(--index) * 10);
  opacity: 0.2;
}
/* price */
.price {
  position: relative;
}
.price h2 {
  text-align: center;
  font-size: calc(var(--index) * 2);
  font-family: 'BebasNeueLite';
  padding-bottom: calc(var(--index) * 1);
  padding-top: calc(var(--index) * 3);
  color: var(--darkBlue);
}
.price h2 strong {
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 2.5);
}
.price h3 {
  text-align: center;
  color: var(--darkBlue);
  padding-bottom: calc(var(--index) * 2);
  padding-top: calc(var(--index) * 1);
}
.price h4 {
  color: var(--darkPink);
  text-align: center;
  font-size: calc(var(--index) * 1);
}
.price h4 strong {
  font-weight: 800;
  font-size: calc(var(--index) * 1.2);
}
.price h5 {
  color: var(--darkBlue);
  text-align: center;
  font-size: calc(var(--index) * 1.2);
  margin-top: calc(var(--index) * -1.5);
  font-weight: 900;
  padding-bottom: calc(var(--index) * 2);
}
.price p {
  color: var(--pink);
  text-align: center;
  padding-bottom: calc(var(--index) * 2);
  font-size: calc(var(--index) * 1);
}
.price__table {
  margin: 0 auto;
  display: flex;
  /* background-color: red; */
  justify-content: center;
  font-family: 'BebasNeueBook';
  font-size: calc(var(--index) * 1.3);
  padding-bottom: calc(var(--index) * 4);
}
.price__table td {
  border-bottom: 2px solid var(--darkBlue);
  padding: calc(var(--index) * 0.3) calc(var(--index) * 1);
}
.price__table span {
  font-size: calc(var(--index) * 0.9);
  font-family: 'BebasNeueRegular';
}
.price__num {
  color: var(--darkBlue);
  font-family: 'BebasNeueBook';
  font-weight: 900;
  text-align: center;
}
.price p {
  font-size: calc(var(--index) * 1.5);
}
.table-btn {
  color: var(--white);
  background-color: var(--darkBlue);
  border-radius: 20px;
  padding: calc(var(--index) * 0.1) calc(var(--index) * 0.5);
  font-size: calc(var(--index) * 1);
  font-family: 'BebasNeueRegular';
  margin-bottom: calc(var(--index) * 3);
}
.price__btn1 {
  position: absolute;
  top: calc(var(--index) * 4.2);
  left: calc(var(--index) * -1.3);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--darkBlue);
  border-radius: 28px;
}
.price__btn2 {
  position: absolute;
  top: calc(var(--index) * 19.5);
  right: calc(var(--index) * -3.5);
  width: calc(var(--index) * 10);
  height: calc(var(--index) * 2);
  border: none;
  background-color: var(--pink);
  border-radius: 28px;
}
.price__snowflake {
  position: absolute;
  top: calc(var(--index) * 5);
  left: calc(var(--index) * 1);
  width: calc(var(--index) * 13);
  opacity: 0.3;
  z-index: -1;
}
/* footer */
.footer {
  background-color: var(--darkBlue);
}
.footer__container {
  width: calc(var(--index) * 46);
  /* background-color: red; */
  display: flex;
  margin: 0 auto;
  padding-top: calc(var(--index) * 2);
  padding-bottom: calc(var(--index) * 3);
}
.footer__logo1 img {
  display: none;
  width: calc(var(--index) * 6);
  height: calc(var(--index) * 3.5);
}
.footer__content {
  padding-left: calc(var(--index) * 2);
}
.footer__info h3 {
  font-family: 'BebasNeueLite';
  font-size: calc(var(--index) * 0.8);
  color: var(--white);
  padding-bottom: calc(var(--index) * 1);
  letter-spacing: 1px;
}
.footer__info h3,
.footer__content a {
  text-decoration: none;
  color: var(--white);
  text-transform: uppercase;
}
.footer__tel {
  display: block;
  font-family: 'BebasNeueRegular';
  font-size: calc(var(--index) * 1);
  /* padding-left: calc(var(--index) * 2); */
  letter-spacing: 1px;
  text-align: right;
}
.footer__tel div {
  padding-bottom: calc(var(--index) * 0.7);
}
.footer__info {
  display: flex;
}
.footer__logo2 img {
  width: calc(var(--index) * 15);
  margin: auto;
  display: flex;
  padding-bottom: calc(var(--index) * 1);
}
.footer__logoPuri img {
  width: calc(var(--index) * 25);
  margin: auto;
  display: flex;
}
.footer__social div {
  padding-bottom: calc(var(--index) * 0.5);
}
.footer__social img {
  width: calc(var(--index) * 1.2);
  padding-left: calc(var(--index) * 0.5);
}
.footer__whatsapp:hover,
.footer__telegram:hover,
.footer__instagram:hover,
.footer__vk:hover,
.footer__social img:hover {
  scale: 1.2;
  transition: scale 0.2s ease-in;
  cursor: pointer;
}
.footer__container2 {
  display: flex;
  margin: auto;
}
.up,
.down {
  width: 20px;
  background-color: var(--pink);
  border-radius: 50%;
  padding: 10px;
  opacity: 0.5;
  position: fixed;
}
.up {
  bottom: 70px;
  left: 10px;
  z-index: 99;
}
.down {
  bottom: 20px;
  left: 10px;
  rotate: 180deg;
  z-index: 99;
}

.baner__home {
  width: 100vw;
  margin-top: calc((var(--index) * 3.5) + (var(--index) * 0.6));
}
.baner__home2 {
  display: none;
}
/* media */

@media (max-width: 900px) {
  .baner__home {
    display: none;
  }
  .baner__home2 {
    width: 100vw;
    margin-top: calc((var(--index) * 3.5) + (var(--index) * 0.6));
    display: flex;
  }
  .header__nav ul {
    font-size: calc(var(--index) * 1.3);
  }
  .home__img {
    width: calc(var(--index) * 22);
  }
  .home__img-treeth {
    bottom: calc(var(--index) * -2);
  }
  .home__img-snowflake {
    left: calc(var(--index) * 22);
  }
  .newYear__month-img {
    display: none;
  }
  .home__sochi {
    display: none;
  }
  .home__button-festival {
    padding: calc(var(--index) * 0.5) calc(var(--index) * 2.5);
  }
  .video {
    width: 80vw;
    height: 40vh;
  }
  .vedalife__content {
    max-width: 80vw;
    padding-right: 0;
    padding-left: 0;
    margin: auto;
  }
  .registraition__content {
    padding-inline: calc(var(--index) * 3);
    padding-right: calc(var(--index) * 2);
  }
  .registraition__title {
    margin-top: calc(var(--index) * 3);
    font-size: calc(var(--index) * 1.8);
  }
  .registraition__text p:last-child {
    margin-bottom: calc(var(--index) * 3);
  }
  .registraition__img img {
    margin-top: calc(var(--index) * 4);
    margin-left: inherit;
    z-index: 4;
  }
  .registraition__content a {
    font-size: calc(var(--index) * 1.8);
  }
  .programmFestival__title,
  .vedalife__title,
  .description__title,
  .festival__title {
    font-size: calc(var(--index) * 1.8);
  }
  .festival {
    padding-top: calc(var(--index) * 2);
  }
  .festival__img img {
    padding-bottom: calc(var(--index) * 2);
  }
  .festival__btn {
    bottom: calc(var(--index) * 5);
    right: calc(var(--index) * -5);
  }
  .description__btnEnd2 {
    bottom: calc(var(--index) * 31);
  }
  .price__table {
    font-size: calc(var(--index) * 1.5);
  }
  .table-btn {
    font-size: calc(var(--index) * 1.5);
  }
  .footer__logo1 {
    position: absolute;
    opacity: 0.2;
  }
  .footer__logo1 img {
    width: calc(var(--index) * 20);
    height: calc(var(--index) * 15);
  }
  .footer__logo2 img {
    margin: inherit;
    margin-left: calc(var(--index) * 5);
  }
  .footer__logoPuri img {
    margin: inherit;
    margin-left: calc(var(--index) * 5);
  }
  .footer__container {
    width: calc(var(--index) * 50);
    flex-direction: column;
    margin: inherit;
    margin-left: calc(var(--index) * 3);
  }
  .footer__container2 {
    flex-direction: column-reverse;
    margin: inherit;
    justify-content: start;
  }
  .footer__content {
    padding-left: calc(var(--index) * 2);
  }
  .footer__info h3 {
    font-size: calc(var(--index) * 1.3);
    letter-spacing: 1.3px;
  }
  .footer__tel {
    font-size: calc(var(--index) * 1.5);
  }
}
@media (max-width: 800px) {
  .home__img {
    width: calc(var(--index) * 25);
  }
  .newYear__month-img {
    width: calc(var(--index) * 8);
  }
  .home__sochi {
    z-index: -1;
    position: relative;
    font-size: calc(var(--index) * 3);
  }
  .home__date h3 {
    font-size: calc(var(--index) * 6);
  }
  .registraition {
    display: block;
    padding-right: inherit;
  }
  .registraition__content {
    margin: auto;
    width: calc(var(--index) * 32);
    /* text-align: center; */
    margin-top: calc(var(--index) * 2);
  }
  .registraition__img {
    display: flex;
    justify-content: center;
  }
  .registraition__img img {
    margin-top: calc(var(--index) * 0);
    margin-bottom: calc(var(--index) * 3);
  }
  .registraition__title {
    padding-top: calc(var(--index) * 2);
  }
  .festival {
    flex-direction: column-reverse;
  }
  .festival__content {
    width: 80vw;
    /* display: flex; */
    margin: auto;
    /* margin-right: calc(var(--index) * 4);
    margin-left: calc(var(--index) * 4); */
  }
  .festival__img {
    margin: auto;
  }
  .vedalife {
    flex-direction: column-reverse;
    padding-top: calc(var(--index) * 0);
  }
  .vedalife__img {
    margin: auto;
    padding-top: calc(var(--index) * 2);
  }
  .vedalife__img img,
  .festival__img img {
    width: calc(var(--index) * 28);
  }
  .description__snowflake4,
  .speaker__snowflake2 {
    display: none;
  }
}
@media (max-width: 750px) {
  .header__logo2,
  .header__social-box {
    display: none;
  }
  .home__button-festival {
    font-size: calc(var(--index) * 1.4);
    padding: calc(var(--index) * 0.5) calc(var(--index) * 1.7);
  }
  .newYear {
    position: relative;
  }
  .newYear__snowflake img {
    position: absolute;
    top: calc(var(--index) * 2);
    left: calc(var(--index) * -1);
    opacity: 0.2;
    z-index: 1;
  }
  .newYear__date {
    z-index: 3;
  }
  .newYear__title h3 {
    margin-left: calc(var(--index) * 3);
  }
  .newYear__date h3 {
    padding-top: calc(var(--index) * 2);
  }
  .newYear {
    flex-direction: column;
  }
  .newYear__month-img {
    display: none;
  }
  .newYear__title,
  .newYear__date,
  .newYear__month {
    z-index: 3;
  }
  .newYear__snowflake img {
    top: calc(var(--index) * 0);
    width: calc(var(--index) * 20);
    height: calc(var(--index) * 20);
    z-index: 1;
  }
  .vedalife__title {
    padding-top: calc(var(--index) * 5);
  }
}
@media (max-width: 700px) {
  .header__logo1 img {
    width: calc(var(--index) * 3);
    height: calc(var(--index) * 2);
    padding-left: calc(var(--index) * 0.5);
  }
  .header__logo2 img {
    width: calc(var(--index) * 7);
    height: calc(var(--index) * 2.3);
    padding-left: calc(var(--index) * 0.7);
  }
  .video {
    width: 90vw;
    height: 45vh;
  }
  .videoRoot {
    width: 90vw;
    height: 90vh;
  }
  .item iframe {
    width: 90vw;
    height: 45vh;
  }
  .programmFestival {
    flex-direction: column-reverse;
  }
  .programmFestival__content {
    width: 85vw;
    margin: auto;
  }
  .festival__text {
    width: 80vw;
    margin: 0 auto;
    padding: 0;
  }
  .programmFestival__title,
  .vedalife__title,
  .description__title,
  .festival__title {
    font-size: calc(var(--index) * 1.8);
  }
  .programmFestival__img {
    margin: auto;
  }
  .programmFestival__img img {
    width: calc(var(--index) * 28);
    margin-top: calc(var(--index) * 2);
  }
  .festival {
    padding-top: calc(var(--index) * 2);
    padding-bottom: calc(var(--index) * 2);
  }
  .festival__img {
    padding-bottom: calc(var(--index) * 2);
  }
  .description {
    padding-top: calc(var(--index) * 2);
  }
  .description__box {
    flex-direction: column;
  }
  .description__content {
    width: inherit;
    text-align: center;
    margin-left: calc(var(--index) * 5);
    margin-right: calc(var(--index) * 5);
  }
  .description__img {
    margin: auto;
  }
  .description__img img {
    width: calc(var(--index) * 28);
    margin-bottom: calc(var(--index) * 3);
    margin-right: 0;
  }
  .speakers {
    position: relative;
  }
  .speakers__snowflake {
    display: flex;
    position: absolute;
    top: calc(var(--index) * -7);
    left: calc(var(--index) * -15);
    width: calc(var(--index) * 17);
    opacity: 0.1;
    z-index: 1;
  }
  .speaker,
  .speaker2 {
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    padding-top: calc(var(--index) * 0);
  }
  .speakers {
    margin-bottom: calc(var(--index) * 3.5);
    flex-direction: column-reverse;
    justify-content: center;
  }
  .speakers__date {
    margin: auto;
    padding-top: calc(var(--index) * 2);
  }
  .speakers__date h3 {
    z-index: 4;
    position: relative;
  }
  .speakers__year img {
    padding-left: inherit;
    padding-top: inherit;
    padding-bottom: calc(var(--index) * 2);
  }
  .speakers__year {
    margin: auto;
    z-index: 4;
  }
  .speakers__title {
    padding-top: calc(var(--index) * 2);
    z-index: 4;
  }
  .speaker__box {
    margin: 0 auto;
    width: inherit;
  }
  .speaker__text {
    padding-bottom: calc(var(--index) * 1);
    width: 90vw;
  }
  .speaker__text p {
    font-size: calc(var(--index) * 1.5);
  }
  .speaker__img img {
    width: calc(var(--index) * 30);
    margin-bottom: calc(var(--index) * 3);
  }
  .speaker__title h2 {
    font-size: calc(var(--index) * 2.5);
  }
  .btnModal {
    padding: 7px;
    font-size: calc(var(--index) * 1.5);
  }
  .speaker__img img {
    margin-top: calc(var(--index) * 3);
  }
  .recomendation__box {
    display: flex;
    flex-direction: column;
  }
  .recomendation__img img {
    width: calc(var(--index) * 30);
    display: flex;
    margin: auto;
  }
  .recomendation h2 {
    font-size: calc(var(--index) * 3);
  }
  .recomendation__img h3 {
    font-size: calc(var(--index) * 2.2);
  }
  .description-title-h1 {
    font-size: calc(var(--index) * 3);
  }
  .hram__text {
    width: 80vw;
    margin: auto;
  }
  .registraition__content {
    width: inherit;
    margin-right: calc(var(--index) * 2);
    margin-left: calc(var(--index) * 2);
  }
  .videoAshram {
    margin-top: calc(var(--index) * 4);
    width: 90vw;
    height: 45vh;
  }
}
@media (max-width: 650px) {
  .home__button-festival {
    font-size: calc(var(--index) * 1.4);
    padding: calc(var(--index) * 0.5) calc(var(--index) * 1.7);
    margin-top: calc(var(--index) * 10);
  }
}
@media (max-width: 600px) {
  .home__img {
    width: calc(var(--index) * 22);
  }
  .header__social,
  .header__tel,
  .header__logo1 {
    display: none;
  }
  .header__logo2 {
    display: none;
  }
  .header__nav ul {
    font-size: calc(var(--index) * 1.5);
  }

  .home__date h3 {
    font-size: calc(var(--index) * 6);
    padding-top: calc(var(--index) * 1);
  }
  .home__date-jun {
    left: calc(var(--index) * 6);
    font-size: calc(var(--index) * 1.3);
  }
  .home__date-dec {
    font-size: calc(var(--index) * 1.3);
  }
  .newYear__month-img {
    width: calc(var(--index) * 6);
    padding-left: calc(var(--index) * -2);
    padding-top: calc(var(--index) * 10);
  }
  .vedalife__title {
    padding-top: calc(var(--index) * 5);
  }
  .festival__btn,
  .programmFestival__btn,
  .description__btn,
  .description__btn2,
  .description__btnEnd,
  .festival__snowflake2,
  .description__btnEnd2,
  .speaker__btn1,
  .speaker__btn2,
  .price__btn1,
  .price__btn2 {
    display: none;
  }
  .festival__snowflake1,
  .programmFestival__snowflake1,
  .description__snowflake1,
  .description__snowflake2,
  .description__snowflake3,
  .description__snowflake4,
  .description__snowflake5 {
    opacity: 0.1;
    width: calc(var(--index) * 30);
    height: calc(var(--index) * 30);
  }
  .programmFestival__snowflake1 {
    opacity: 0.1;
    width: inherit;
    height: calc(var(--index) * 30);
  }
  .registraition__title h2 {
    font-size: calc(var(--index) * 3);
  }
  .registraition__text {
    z-index: 3;
  }
  .registraition__snowflake {
    z-index: 1;
  }
  .registraition__content a {
    font-size: calc(var(--index) * 2);
  }
  .registraition__img {
    margin-top: calc(var(--index) * 3);
  }
}
@media (max-width: 500px) {
  .home__img {
    width: calc(var(--index) * 20);
    border-radius: 0 calc(var(--index) * 11) calc(var(--index) * 10) 0;
    padding-top: calc(var(--index) * 2);
  }
  .newYear__month-img {
    width: calc(var(--index) * 10);
    padding-top: calc(var(--index) * 4);
    margin-left: calc(var(--index) * -2);
    padding-bottom: calc(var(--index) * 4);
  }
  .vedalife {
    margin-left: inherit;
  }
  .footer__logo2 img {
    width: calc(var(--index) * 10);
    padding-top: calc(var(--index) * 2);
  }
  .footer__logoPuri img {
    width: calc(var(--index) * 10);
  }
  .home__img-snowflake {
    display: flex;
    left: calc(var(--index) * 4.5);
  }

  .slider {
    width: 100%;
  }
}
/* my mobile */
@media (max-width: 450px) {
  .home__img {
    padding-top: calc(var(--index) * 3);
    padding-bottom: calc(var(--index) * 5);
    border-radius: 0 calc(var(--index) * 9) calc(var(--index) * 11) 0;
    /* border-radius:0 50% 50% 0; */
    /* position: absolute; */
  }
  .home__date {
    /* background-color:red; */
    display: block;
    text-align: center;
  }
  .home__sochi {
    font-size: calc(var(--index) * 2);
  }
  .newYear__month {
    padding-left: calc(var(--index) * 2);
  }
  .home__date-jun {
    left: calc(var(--index) * 8);
  }
  .footer__logoPuri img {
    width: calc(var(--index) * 20);
  }
  .newYear__month-img {
    padding-top: calc(var(--index) * 6);
    padding-bottom: calc(var(--index) * 1);
    margin-left: calc(var(--index) * -3);
  }
  .registraition__content {
    margin-top: calc(var(--index) * 1);
  }
  .price__table {
    font-size: calc(var(--index) * 2);
  }
  .hram__img-snowflake {
    right: calc(var(--index) * 5);
  }
  .hram h3,
  .reviews h3 {
    font-size: calc(var(--index) * 2);
  }
}
@media (max-width: 400px) {
  .home__img {
    border-radius: 0 calc(var(--index) * 9.5) calc(var(--index) * 11) 0;
  }
  .home__date h3 {
    padding-left: calc(var(--index) * 0.5);
  }
  .newYear__month {
    padding-left: calc(var(--index) * 0.5);
  }
  .home__date-jun {
    left: calc(var(--index) * 6);
  }
  .newYear__month-img {
    padding-top: calc(var(--index) * 8);
    padding-bottom: calc(var(--index) * 2);
  }
  .header__social-box {
    display: none;
  }
}
@media (max-width: 350px) {
  .home__sochi {
    padding-top: inherit;
  }
  .home__date h3 {
    padding-top: calc(var(--index) * 4);
  }
  .home__img {
    width: calc(var(--index) * 17);
    padding-top: calc(var(--index) * 2);
    border-radius: 0 calc(var(--index) * 7) calc(var(--index) * 10) 0;
  }
  .footer__container {
    width: inherit;
  }
}
